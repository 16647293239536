import React from 'react'
import { Card } from '@jsluna/react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Hero from '../components/Hero'
import routes from '../config/routes'
import './index.scss'

export const TermsAndConditions = () => {
  return (
    <Layout testid="tacs-page">
      <SEO title="Terms and Conditions" />
      <section className="page">
        <Hero />
        <div className="page_content">
          <Card className="tandc__wrapper">
            <h3>Terms and Conditions</h3>
            <ol className="tandc_list">
              <li className="tandc_list_item">
                Sainsbury’s Active Kids holiday clubs 2020 offers a summer
                holiday club for children aged 5-15 in 150 locations across the
                UK. It is open to all parents and guardians with children aged
                between 5-15.
              </li>
              <li className="tandc_list_item">
                Active Kids Holiday clubs is operated on behalf of Sainsbury’s
                by Premier Education Group PLC (“Premier Education”).
                <> </>
                <strong>
                  You will book directly with and your contract will be with
                  Premier Sports Limited
                </strong>
                <> </>
                (Company Registration No. 03774725), a wholly owned subsidiary
                of Premier Education. Your payment will show as being made to
                ‘Premier Sports Ltd’ on your card statement.
                <> </>
                As part of the booking process you will be required to accept
                the Premier Sports Ltd’s terms and conditions. They can be found
                at:
                <> </>
                <a href="https://www.premier-education.com/terms-of-use/">
                  https://www.premier-education.com/terms-of-use/
                </a>
                . Premier Sports Ltd holds comprehensive public liability and
                professional negligence insurance. All Premier Sports Ltd
                instructors are DBS checked and trained. Emergency first aiders
                are available at all Active Kids sites.
              </li>
              <li className="tandc_list_item">
                Active Kids holiday clubs will take place at schools in 150
                locations between 27th July and 28th Aug 2020. To find your
                nearest venue click
                <> </>
                <Link to={routes.venues}>here</Link>
                <>. </>
                Dates vary between locations and all places at Sainsbury’s
                Active Kids holiday clubs are subject to availability. Up to
                240,000 individual child places (each a “Session”) are available
                throughout the booking period. Each Session will last from 9am
                until 4pm, with registration from 8:45am.
              </li>
              <li className="tandc_list_item">
                To take part there is a charge of £10 per child per Active Kids
                holiday club Session. From time to time we may offer discounted
                places at the Active Kids holiday club. If any discounted places
                are available they will be advertised or made available locally.
              </li>
              <li className="tandc_list_item">
                To book, access the Sainsbury’s Active Kids website at
                <> </>
                <Link to={routes.home}>
                  https://activekids.sainsburys.co.uk
                </Link>
                . You will need to follow instructions to register with Premier
                Education and provide a password.
              </li>
              <li className="tandc_list_item">
                Full details for each child, including any disabilities and/or
                food allergies/intolerances are required together with emergency
                contact details for each Session. We’re sorry but we are unable
                to provide 1:1 care for children. Therefore if there are any if
                you think your child may need such care please contact us. We
                reserve the right to require parents/guardians to collect their
                children from a session at immediate notice should the child be
                unwell or their conduct puts other children or the instructors
                at risk. All Sessions are subject to availability. On the day of
                each Session you will be asked to sign in your child and confirm
                that emergency contact details are correct. To make the booking,
                you must be the parent or Legal Guardian for each child booked.
              </li>
              <li className="tandc_list_item">
                As part of the booking process you will be given the option to
                be re-directed to the Nectar.com website where you can log in
                and redeem Nectar points in exchange for full or part payment
                for a Session. This will generate an Active Kids holiday club
                voucher code. Active Kids holiday club voucher codes are
                available online only at
                <> </>
                <a href="https://www.nectar.com">https://www.nectar.com</a>
                <> </>
                from 10th April 2020 until 27th August 2020. They are not
                available in-store or at Sainsburys.co.uk. Nectar points will be
                deducted from your Nectar account at the time your Active Kids
                holiday club voucher code is issued. If you do not complete your
                booking, you should keep your voucher code secure to be used on
                an Active Kids holiday club booking before 27th August 2020.
                Once issued voucher codes are the responsibility of the Nectar
                card holder. Refunds of Nectar points or replacement codes
                cannot be issued.
              </li>
              <li className="tandc_list_item">
                You may book a maximum of 5 (five) children per booking. You may
                book each child into an unlimited number of Sessions, subject to
                availability. The charge is: £10 per child for each Session
                booked.
                <ol type="a">
                  <li>
                    For example, if you book 2 children for two Sessions each,
                    you’ll pay £40 (4 Sessions at £10 each) and you may choose
                    to fully or partially pay for this using Nectar points.
                  </li>
                </ol>
              </li>
              <li className="tandc_list_item">
                There is no discount for multiple Sessions. Payment must be made
                by credit/debit card or Nectar points at time of booking. We’re
                sorry but we can’t accept any childcare vouchers and cash cannot
                be accepted. This offer is not available in conjunction with any
                other promotion.
              </li>
              <li className="tandc_list_item">
                Once you’ve completed your booking, you will receive an email
                confirming the details and booking. Please check it carefully.
                This email will be sent to the email address provided by you on
                your booking form.
              </li>
              <li className="tandc_list_item">
                We will accept cancellations on the following basis:
                <ol type="a">
                  <li>
                    You may cancel and receive a refund up to 14 days before the
                    day your booked Session is due to take place.
                  </li>
                  <li>
                    If you book less than 14 days before your Session is due to
                    take place you may cancel up to the day before the Session.
                    You accept and agree that the right to cancel is lost once
                    the contract is fully performed. To cancel a booking within
                    these timescales, please email Premier Education
                    <> </>
                    <a href="mailto:activekids@premier-education.com">
                      activekids@premier-education.com
                    </a>
                    <>, or call on </>
                    <a href="tel:01953499088">01953 499088</a>
                    <>
                      . Any refund will be credited by Premier Education to the
                      card used at time of purchase. Premier Education will
                      contact you for card details for any cancellations paid
                      with Nectar points.
                    </>
                  </li>
                </ol>
              </li>
              <li className="tandc_list_item">
                The Promoter reserves the right to cancel any or all Sessions
                for reasons outside their reasonable control (a ‘Force Majeure’
                event). Should such an event occur, the Promoter will contact
                customers as soon as possible or practicable and in any event no
                later than the day of the Session. Where Sessions are cancelled
                by the Promoter under this clause 12 you will receive a full
                refund for those cancelled sessions.
              </li>
              <li className="tandc_list_item">
                Any amendments to bookings are subject to the discretion of
                Premier Education and availability.
              </li>
              <li className="tandc_list_item">
                You may make additional bookings. Each new booking will require
                payment in full by card or Nectar point redemption to the value
                of £10 per attendee per Session.
              </li>
              <li className="tandc_list_item">
                Instructions for arrival, late arrival and collection will be
                provided by Premier Education. We’re sorry, but no refunds are
                available for non-attendance.
              </li>
              <li className="tandc_list_item">
                Session activities will be as shown on the Premier Education
                website. Activities are for representative purposes only and are
                subject to change at Premier Education’s discretion. Some
                activities are not suitable for all children. Please tell us in
                advance if your child has specific conditions or requirements,
                including without limitation, mobility, behaviour and dietary
                requirements. Wherever possible, where you have notified us of
                any disabilities, Premier Education will make reasonable
                adjustments. If this is not possible they will let you know.
                We’re sorry but 1:1 care is not available.
              </li>
              <li className="tandc_list_item">
                Active Kids holiday club Nectar voucher codes are available to
                use until 28 August 2020, subject to Session availability. If
                you wish to pay using Nectar points, an Active Kids Nectar club
                voucher code may be obtained by selecting to pay with Nectar
                points during payment. You will then be directed to the Nectar
                website, where a unique voucher code will be provided. The
                voucher must be used immediately. After this date they will
                expire and have no value. Unused or Expired access codes cannot
                be exchanged for cash or Nectar points. Access codes are single
                use, non-transferrable and may only be used by the named Nectar
                card holder. Sale, auction or retendering of Active Kids holiday
                club e-vouchers for money or otherwise is prohibited.
              </li>
              <li className="tandc_list_item">
                By participating in Active Kids holiday clubs you confirm that
                you accept these terms and conditions and have read and
                acknowledged the Sainsbury’s Privacy Policy. Your personal data
                will be held and used only in accordance with this Policy.
              </li>
              <li className="tandc_list_item">
                These terms and conditions shall be governed by English law and
                the courts of England and Wales shall have exclusive
                jurisdiction.
              </li>
              <li className="tandc_list_item">
                If you have any concerns or queries, please contact the Helpline
                <> </>
                <a href="tel:01953499088">01953 499088*</a>
                <> </>
                *Calls are free from a landline but costs from mobiles may vary.
                The Helpline will be available Monday to Friday (excluding
                public holidays) between 9am and 5pm.
              </li>
              <li className="tandc_list_item">
                The Nectar point collection programme is subject to the Nectar
                collector rules which are set out online at
                <> </>
                <a href="https://www.nectar.com">https://www.nectar.com</a>
                <>. </>
                The Nectar Scheme is owned by Nectar 360 Limited, registered
                number 04224736, a company registered in England and Wales,
                whose registered office is 33 Holborn, London EC1N 2HT.
              </li>
              <li className="tandc_list_item">
                The promoter is Sainsbury’s Supermarkets Ltd, 33 Holborn, London
                EC1N 2HT. Reg 3616722.
              </li>
            </ol>
          </Card>
        </div>
      </section>
    </Layout>
  )
}

export default TermsAndConditions
